import {find, isEmpty} from "lodash";
import {AuthApiActions} from "../api/endpoint";
import {GetAllItems} from "../utility/Form";
export const isBrowser = () => typeof window !== "undefined"

export const getToken = () => {
  return isBrowser() ? localStorage.getItem("pjwt") : "";
}

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("puser")
    ? JSON.parse(window.localStorage.getItem("puser"))
    : {};

export const getLoggedInUserOffices = () =>
  isBrowser() && window.localStorage.getItem("puser_offices")
    ? JSON.parse(window.localStorage.getItem("puser_offices"))
    : [];

export const setUser = ({data = {}}) => {
  // Store token
  const {token, user} = data;
  if (isBrowser()) {
    localStorage.setItem('pjwt', token)
    localStorage.setItem('puser',
      JSON.stringify({...user, ...{hasSuperAdminRole: find((user.roles || []), function(role) {
        return role.name === 'Super Admin';
      })}
    }))
  }
}

export const setLoggedInUserOffices = (offices) =>{
  if(isBrowser())
    localStorage.setItem('puser_offices', JSON.stringify(offices));
}

const removeUser = () => {
  // remove token
  if (isBrowser()) {
    localStorage.removeItem('pjwt');
    localStorage.removeItem('puser');
  }
}

const removeUserOffices = () => {
  if (isBrowser()) {
    localStorage.removeItem('puser_offices');
  }
}

// Login
export const handleLogin = (endpointname, data) => {
  if(isBrowser()){
    return AuthApiActions.authAction(endpointname, data)
  }
  return false
}

// Logged in user offices
export const handleLoggedInUserOffices = async () => {
  try {
    const LoggedInUser = getUser();
    if(!LoggedInUser.hasSuperAdminRole){
      const { data } = await GetAllItems('office-user-mapping.getOfficesByLoggedUser',
        { query: `userId=${LoggedInUser?.id}`});
      setLoggedInUserOffices(isEmpty(data) ? [{my_home_id: "__not_assigned__"}]: data);
    }      
    return Promise.resolve('success');
  } catch (error) {
    console.log('handleLoggedInUserOffices error', error);
    return Promise.reject('failed');
  }
}

export const isLoggedIn = () => {
  const user = getUser();
  return (user.email && getToken())
}

export const logout = () => {
  removeUser();
  removeUserOffices();
}
